.menupanel {
    /* border:2px solid black; */
    position:absolute;
    background-color:lightslategray;
    transform-origin: left top;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    justify-items:flex-start;
    align-items: center;
}

.players1 {
    height:calc(100vmin);
    width:calc(50vmax);
    transform:translateX(calc(50vmax));
}

.players3 {
    height:calc(50vmin);
    width:calc(50vmax);
    transform:translateX(50vmax) translateY(50vmin);
}

.players5 {
    height:calc(50vmin);
    width:calc(100vmax/3);
    transform:translateX(calc(100vmax - (100vmax/3))) translateY(50vmin);
}
.players2, .players6, .players4 {
    height:100vmin;
    width:10vmax;
}

.players2, .players4 {
    transform:translateX(calc(100vmax/2 - 10vmax/2));
}

.players6{
    transform:translateX(calc((100vmax/3) - 10vmax/3));
}

button {
    /*border-radius:1vmin;*/
    height:8vmax;
    width:8vmax;
    /* border:2px solid black; */
    font-size:4vmin;
}
.menuPanelButton {
    /* box-shadow: 0 0 3vmin rgba(0,0,0, 0.5); */
    border:none;
    background-color: transparent;
}
